
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
  } from "reactstrap";
  // core components
  import Header from "components/Headers/Header.js";
  import Calendar from "components/Calendar/Calendar";
  
  const Notification = () => {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="card-bgColor portfolioUserWallet">
          <Row>
            <Col>
            There is no notification!
            </Col>
          </Row>
          
          </div>
        </Container>
      </>
    );
  };
  
  export default Notification;
  