// src/utils/userUtils.js
import { format } from "date-fns";

// Function to format Firestore Timestamp to date string
export function formatInvestmentDate(timestamp) {
  const date = timestamp.toDate();
  return format(date, "dd-MM-yyyy"); // Adjust the format as needed
}

// Utility function to flatten the user data and format dates
export function flattenUserData(user) {
  const { investmentDetails, dob, ...rest } = user;
  const investmentDate = investmentDetails ? investmentDetails.investmentDate : null;
  const startKapital = investmentDetails ? investmentDetails.startCapital : null;
  const goalCapital = investmentDetails ? investmentDetails.goalCapital : null;
  const goalDate = investmentDetails ? investmentDetails.goalDate : null;
  const currentNetworth = investmentDetails ? investmentDetails.currentNetworth : null;
  const formattedInvestmentDate = investmentDate ? formatInvestmentDate(investmentDate) : null;
  const formattedDob = dob ? formatInvestmentDate(dob) : null;
  const formattedGoalDate = goalDate ? formatInvestmentDate(goalDate) : null;
  return { ...rest, investmentDate: formattedInvestmentDate, startKapital, goalCapital, currentNetworth, dob: formattedDob, goalDate: formattedGoalDate };
}

