import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

export const getUserCount = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      return querySnapshot.size;
    } catch (error) {
      console.error("Error fetching user count:", error);
      throw error;
    }
  };

  export const getTotalStartCapital = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      let totalStartCapital = 0;
  
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        const startCapital = Number(userData.investmentDetails?.startCapital) || 0;
        totalStartCapital += startCapital;
      });
  
      return totalStartCapital;
    } catch (error) {
      console.error("Error fetching total start capital:", error);
      throw error;
    }
  };

  export const getTotalCoins = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "coins"));
        return querySnapshot.size;
      } catch (error) {
        console.error("Error fetching coins count:", error);
        throw error;
      }
  };