import { collection, getDocs, updateDoc, deleteDoc, doc, addDoc, query, where } from "firebase/firestore";
import { db } from "../firebase-config";


export const getQuestionsBySurveyType = async (surveyType) => {
  const surveyCollection = collection(db, "survey");
  const q = query(surveyCollection, where("surveyType", "==", surveyType));
  const surveySnapshot = await getDocs(q);
  const surveys = [];

  for (const surveyDoc of surveySnapshot.docs) {
    const questionsCollection = collection(surveyDoc.ref, "questions");
    const questionsSnapshot = await getDocs(questionsCollection);

    questionsSnapshot.forEach(questionDoc => {
      surveys.push({
        id: questionDoc.id,
        surveyId: surveyDoc.id, 
        ...questionDoc.data()
      });
    });
  }
  
  return surveys;
};

export const updateQuestion = async (surveyId, questionId, updatedQuestion) => {
  const questionDoc = doc(db, "survey", surveyId, "questions", questionId);
  await updateDoc(questionDoc, updatedQuestion);
};

export const deleteQuestion = async (surveyId, questionId) => {
  const questionDoc = doc(db, "survey", surveyId, "questions", questionId);
  await deleteDoc(questionDoc);
};

export const addQuestion = async (surveyId, newQuestion) => {
  const questionsCollection = collection(db, "survey", surveyId, "questions");
  const docRef = await addDoc(questionsCollection, newQuestion);
  return docRef; 
};