import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase-config";

export const fetchSurveyResults = async () => {
    const surveyCollection = collection(db, "survey");
    const q = query(surveyCollection, where("surveyType", "==", "APPOINTMENT"));
    const surveySnapshot = await getDocs(q);
  
    const results = [];
  
    for (const surveyDoc of surveySnapshot.docs) {
      console.log(`Survey document found: ${surveyDoc.id}`, surveyDoc.data()); // Log each survey document
      const resultsCollection = collection(surveyDoc.ref, "result");
      const resultsSnapshot = await getDocs(resultsCollection);
  
      if (resultsSnapshot.empty) {
        console.log(`No results found for survey document: ${surveyDoc.id}`);
        continue;
      }
  
      resultsSnapshot.forEach(resultDoc => {
        const data = resultDoc.data();
        console.log(`Fetched result data from ${surveyDoc.id}:`, data); // Log each result data
        results.push({
          id: resultDoc.id,
          name: data.name,
          email: data.email,
          timeFrom: data.appointmentDate.timeFrom.toDate(),
          timeTo: data.appointmentDate.timeTo.toDate(),
        });
      });
    }
  
    console.log("All fetched results:", results); // Log all results
    return results;
  };