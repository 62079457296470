import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { fetchSurveyResults } from 'services/appointmentService';
import Header from "components/Headers/Header.js";
import { format, isSameDay } from 'date-fns';
import {
  Container,
  Row,
  Col,
} from "reactstrap";

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const loadSurveyResults = async () => {
      const results = await fetchSurveyResults();
      console.log("Survey results:", results);
      if (!results || results.length === 0) {
        console.log("No survey results found");
        return;
      }
      const formattedEvents = results.map(result => ({
        id: result.id,
        title: result.name || 'Survey Result',
        start: result.timeFrom,
        end: result.timeTo,
        extendedProps: {
          email: result.email,
        },
      }));
      console.log("Formatted events:", formattedEvents);
      setEvents(formattedEvents);

      // Filter today's events initially
      const today = new Date();
      const todaysEvents = formattedEvents.filter(event =>
        isSameDay(new Date(event.start), today)
      );
      setTodayEvents(todaysEvents);
    };

    loadSurveyResults();
  }, []);

  const handleDateClick = (arg) => {
    const selectedDate = arg.date;
    setSelectedDate(selectedDate);
  
    // Filter events for the selected date
    const selectedEvents = events.filter(event =>
      isSameDay(new Date(event.start), selectedDate)
    );
  
    // Sort events by start time
    selectedEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
  
    setTodayEvents(selectedEvents);
  };
  

  const formatDate = (date) => format(date, 'MMMM d, EEEE');

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <h3 className="text-white text-xl mb-4">Termin</h3>
        <div className="card-bgColor portfolioUserWallet">
          <Row>
            <Col xl="9">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                dateClick={handleDateClick}
              />
            </Col>
            <Col xl="3">
              <h5 className="text-white text-xl">Zeitpläne</h5>
              <p className="text-white text-sm">{formatDate(selectedDate)}</p>
              <hr />
              {todayEvents.length === 0 ? (
                <p>No appointments for today</p>
              ) : (
                <ul className="appointmentsToday">
                  {todayEvents.map(event => (
                    <li key={event.id}>
                      <strong>{event.title}</strong> <br />
                      <span className="text-sm">{event.extendedProps.email}</span> <br />
                      {format(new Date(event.start), 'HH:mm')} - {format(new Date(event.end), 'HH:mm')}
                    </li>
                  ))}
                </ul>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Calendar;
