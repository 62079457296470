// src/TradingViewWidget.js
import React, { useEffect } from 'react';

const CoinStatsWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.coinstats.app/widgets/coin-chart-widget.js";
    script.async = true;
    document.body.appendChild(script);
    
    return () => {
      // Cleanup script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <coin-stats-chart-widget
              type="medium"
              coin-id="ethereum"
              width="435"
              chart-height="170"
              currency="USD"
              locale="en"
              bg-color="#202020"
              text-color="#FFFFFF"
              status-up-color="rgba(0,0,0,1)"
              status-down-color="#FE4747"
              buttons-color="#1C1B1B"
              chart-color="rgba(94,86,76,1)"
              chart-gradient-from="rgba(0,0,0,0.07)"
              chart-gradient-to="rgba(0,0,0,0)"
              chart-label-background="#000000"
              candle-grids-color="rgba(255,255,255,0.1)"
              border-color="rgba(20,20,20,1)"
              font="Roboto, Arial, Helvetica"
              style="max-width: 435px; width: 100%;"
            ></coin-stats-chart-widget>
          `
        }}
      />
    </div>
  );
};

export default CoinStatsWidget;
