import React, { useState, useEffect } from "react";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { Button, Modal, Form } from 'react-bootstrap';
import AddNewUserBg from '../../assets/img/icons/modalHeaderBg.png'; 
import { Col, Row } from "reactstrap";

const AddCoinModal = ({ show, handleClose, refreshCoins, onSubmit, coin }) => {
  const [symbol, setSymbol] = useState("");
  const [color, setColor] = useState("#000000");
  const [isFavorite, setIsFavorite] = useState(false);
  const [order, setOrder] = useState(0);

  useEffect(() => {
    if (coin) {
      // Pre-fill the form fields with the existing coin data if editing
      setSymbol(coin.symbol || "");
      setColor(coin.color || "#000000");
      setIsFavorite(coin.isFavorite || false);
      setOrder(coin.order || 0);
    } else {
      // Reset the form fields when adding a new coin
      setSymbol("");
      setColor("#000000");
      setIsFavorite(false);
      setOrder(0);
    }
  }, [coin]);

  const divStyle = {
    backgroundImage: `url(${AddNewUserBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '40px 0',
    color: 'white', // Ensure the text is readable over the background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newCoin = {
        symbol,
        color, // Save color directly as hex value
        isFavorite,
        order: parseInt(order, 10),
      };

      if (coin) {
        // If coin exists, update the existing document
        const coinDoc = doc(db, "coins", coin.id);
        await updateDoc(coinDoc, newCoin);
        onSubmit("Kryptowährung erfolgreich aktualisiert.");
      } else {
        // Otherwise, add a new document
        const coinsCollection = collection(db, "coins");
        await addDoc(coinsCollection, newCoin);
        onSubmit("Neue Kryptowährung erfolgreich hinzugefügt.");
      }

      handleClose();
      refreshCoins(); // Refresh the list of coins
    } catch (error) {
      console.error("Error submitting coin: ", error);
    }
  };

  return (
    <>
      <Modal show={show} className="addNewUserModal addNewWalletModal" onHide={handleClose}>
        <Modal.Header style={divStyle}>
          <Modal.Title>{coin ? "Krypto bearbeiten" : "Neue Krypto hinzufügen"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="addNewKryptoInList">
            <Form.Group controlId="formSymbol">
              <Form.Label>Symbol</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter coin symbol"
                value={symbol}
                onChange={(e) => setSymbol(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formColor">
              <Form.Label>Color</Form.Label>
              <Form.Control
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formisFavorite">
              <Form.Check
                type="checkbox"
                label="Is Favourite"
                checked={isFavorite}
                onChange={(e) => setIsFavorite(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId="formOrder">
              <Form.Label>Order</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter order"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                required
              />
            </Form.Group>
            <Row className="cryptoLsitModalButton">
                <Col>
                  <span onClick={handleClose} className="dicardChng">Verwerfen</span>
                </Col>
                <Col>
                  <button type="submit">{coin ? "Änderungen speichern" : "Auswählen"}</button>
                </Col>
              </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCoinModal;
