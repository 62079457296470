
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import LogOutIcon from "../../assets/img/icons/shutdown.png";
var ps;

const Sidebar = (props) => {
  const { dispatch } = useContext(AuthContext);

      const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
      };

  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes, closeCollapse) => {
    return routes.map((prop, key) => {
      if (prop.children) {
        return <DropdownLink key={key} prop={prop} closeCollapse={closeCollapse} />;
      }
      return (
        <NavItem key={key} className={prop.display}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            <img src={prop.icon} alt="" />
            <img src={prop.iconActive} className="activeIcon" alt="" />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  
  const DropdownLink = ({ prop, closeCollapse }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    const toggle = () => setDropdownOpen(!dropdownOpen);
    
    
    return (
      <Dropdown className="displayTrue" nav inNavbar isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle nav caret>
        <img src={prop.icon} alt="" />
          {prop.name}
        </DropdownToggle>
        <DropdownMenu>
          {prop.children.map((child, index) => (
            <DropdownItem key={index}>
              <NavLink
                to={child.layout + child.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                className="nav-link"
              >
                <img src={child.icon} alt="" />
                <img src={child.iconActive} className="activeIcon" alt="" />
                {child.name}
              </NavLink>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };
  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-dark sidebar-bgColor"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0 text-left" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
      
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
          
          </div>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes, closeCollapse)}</Nav>
          {/* Divider */}
          <hr className="my-3" />
          {/* Heading */}
          {/* Navigation */}
        
                <Media className="align-items-center absolute bottom-0 profilUser">
                  <span className="avatar avatar-sm rounded-circle logoutBtn" onClick={handleLogout}>
                  <img src={LogOutIcon} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-white text-sm font-weight-bold">
                    Admin
                    </span>

                  </Media>
                </Media>
                          
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
