// FileManagement.js
import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage'; // Import storage functions from Firebase v9
import { storage, db } from '../../firebase-config'; // Import your initialized storage from firebaseConfig
import { collection, getDocs, deleteDoc, doc , addDoc, serverTimestamp } from 'firebase/firestore'; // Firestore functions
import trashIcon from '../../assets/img/icons/trash.png'; 
import { Label } from 'reactstrap'; // Keep this as is for reactstrap Label
import { toast, ToastContainer } from 'react-toastify';

const FileManagement = ({ userId }) => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');  // For document title
    const [description, setDescription] = useState('');  // For document description
    const [progress, setProgress] = useState(0);
    const [documents, setDocuments] = useState([]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file || !userId || !title || !description) {
            alert('Please fill in all fields and select a file');
            return;
        }

        const fileExtension = file.name.split('.').pop().toUpperCase(); // Get the file extension (e.g., PDF)
        const fileType = fileExtension === 'PDF' ? 'PDF' : 'Other'; // Determine file type based on extension

        const storageRef = ref(storage, `users/${userId}/documents/${file.name}`); // Create a storage reference for the file

        const uploadTask = uploadBytesResumable(storageRef, file); // Start the file upload

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progressPercent);
            },
            (error) => {
                console.error('Upload failed:', error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref); // Get the download URL of the file

                // Add the document details to Firestore in the user's 'documents' subcollection
                const docRef = await addDoc(collection(db, `users/${userId}/documents`), {
                    createdAt: serverTimestamp(),  // Add the server timestamp
                    description: description,      // Description from user input
                    isActive: true,                // Hardcoded as true
                    path: `users/${userId}/documents/${file.name}`,  // The path to the file in storage
                    seen: false,                   // Default set to false, you can change this based on your logic
                    title: title,                  // Title from user input
                    type: fileType,                // File type (e.g., PDF)
                    downloadURL: downloadURL       // Download URL of the uploaded file
                });

                console.log("Document created with ID: ", docRef.id);
                toast.success('Datei erfolgreich hochgeladen');

            setFile(null);
            setTitle('');
            setDescription('');
            setProgress(0);
                // Reload documents after upload
                loadUserDocuments();
            }
        );
    };

    // Function to load documents from Firestore subcollection
    const loadUserDocuments = async () => {
        if (!userId) {
            alert('Please enter a User ID');
            return;
        }

        const docsRef = collection(db, `users/${userId}/documents`);
        const querySnapshot = await getDocs(docsRef);

        const documentsList = [];
        querySnapshot.forEach((doc) => {
            documentsList.push({
                id: doc.id,
                ...doc.data() // Get all fields from the document
            });
        });

        setDocuments(documentsList);
    };

    const handleDelete = async (docId, filePath) => {
        try {
            // Step 1: Delete the file from Firebase Storage
            const fileRef = ref(storage, filePath);
            await deleteObject(fileRef);
            console.log("Deleted file from storage");
    
            // Step 2: Delete the Firestore document
            const docRef = doc(db, `users/${userId}/documents`, docId);
            await deleteDoc(docRef);
            console.log("Deleted document from Firestore");
    
            toast.success('Datei erfolgreich geloescht');
    
            // Reload documents after deletion
            loadUserDocuments();
        } catch (error) {
            console.error('Error deleting file or Firestore document:', error);
        }
    };

    useEffect(() => {
        if (userId) {
            loadUserDocuments();
        }
    }, [userId]);

    return (
        <div>
            <div className='uploadDoc'>
            <h2>Datei zum Firebase-Speicher hochladen</h2>
            <label>Datei:
            <input
                type="file"
                onChange={handleFileChange}
            />
            </label>
            <label>Titel
            <input
                type="text"
                placeholder=""
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            </label>
            <label>Beschreibung
            <input
                type="text"
                placeholder=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            </label>
            <button onClick={handleUpload}>Hochladen</button>
            <div className='proggRes'>Fortschritt: <span>{progress}%</span></div>
            </div>
            <h2>Dokumente:</h2>
           

            <table className='usersDocsTbl'>
                <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map((doc, index) => (
                        <tr key={index}>
                            <td onClick={() => window.open(doc.downloadURL, '_blank')}>{doc.title}</td> {/* Display the title from Firestore */}
                            <td>
                                <button onClick={() => handleDelete(doc.id, doc.path)}><img src={trashIcon} alt="Delete"/></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FileManagement;