
import { useState, useEffect } from "react";
import starsImg from '../assets/img/icons/reviewsStar.png';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Badge,
  Card,
  Col,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { collection, getDocs, query, where, doc, updateDoc, limit, startAfter } from "firebase/firestore";
import { db } from "../firebase-config";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [filter, setFilter] = useState("all"); // State for active filter
  const [lastVisible, setLastVisible] = useState(null); // Store the last visible document for pagination
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Check if there are more reviews to load

  const pageSize = 10; // Number of reviews per page

  // Fetch reviews with pagination
  const fetchReviews = async (next = false) => {
      if (loading) return; // Prevent multiple requests at the same time

      setLoading(true);
      try {
          // Build the query with pagination and filtering
          let q = query(
              collection(db, "survey"),
              where("surveyType", "==", "RATING"),
              limit(pageSize)
          );

          // If we're loading the next page, start after the last visible document
          if (next && lastVisible) {
              q = query(
                  collection(db, "survey"),
                  where("surveyType", "==", "RATING"),
                  startAfter(lastVisible),
                  limit(pageSize)
              );
          }

          const surveySnapshot = await getDocs(q);
          const lastDoc = surveySnapshot.docs[surveySnapshot.docs.length - 1]; // Get the last document

          const results = [];

          // Loop through each survey document
          for (const surveyDoc of surveySnapshot.docs) {
              const resultsCollection = collection(surveyDoc.ref, "result");
              const resultsSnapshot = await getDocs(resultsCollection);

              if (!resultsSnapshot.empty) {
                  resultsSnapshot.forEach(resultDoc => {
                      const data = resultDoc.data();
                      results.push({
                          id: resultDoc.id,
                          surveyId: surveyDoc.id,
                          name: data.name,
                          ratings: data.ratings,
                          review: data.review,
                          ratingStatus: data.ratingStatus
                      });
                  });
              }
          }

          // Update reviews and pagination data
          setHasMore(surveySnapshot.docs.length === pageSize); // Check if we have more reviews to load
          setLastVisible(lastDoc); // Set the last visible document for the next page

          // Append new reviews if loading more, otherwise reset the reviews list
          setReviews(prev => (next ? [...prev, ...results] : results));
      } catch (error) {
          console.error("Error fetching reviews: ", error);
      }
      setLoading(false);
  };

  // Fetch the initial reviews when component mounts
  useEffect(() => {
      fetchReviews();
  }, []);

  // Handle status change for reviews
  const handleStatusChange = async (surveyId, resultId, status) => {
      try {
          const resultDocRef = doc(db, "survey", surveyId, "result", resultId);
          await updateDoc(resultDocRef, { ratingStatus: status });
          setReviews(prevReviews =>
              prevReviews.map(review =>
                  review.id === resultId ? { ...review, ratingStatus: status } : review
              )
          );
      } catch (error) {
          console.error("Error updating status: ", error);
      }
  };

  // Function to filter reviews based on the current filter
  const filteredReviews = () => {
      if (filter === "approved") {
          return reviews.filter(review => review.ratingStatus === "Approved");
      } else if (filter === "declined") {
          return reviews.filter(review => review.ratingStatus === "Declined");
      }
      return reviews;
  };

  return (
      <>
          <Header />
          <Container className="mt--7" fluid>
              <Row className="gx-3">
                  <Col className="mb-5 mb-xl-0" xl="9">
                      <h3 className="text-white text-xl mb-4">Bewertungen</h3>
                  </Col>
              </Row>
              <Row className="revCButtons mb-4">
                  <Col className="" xl="12">
                      {/* Conditionally apply the "active" class */}
                      <a
                          href="#"
                          className={filter === "all" ? "active" : ""}
                          onClick={() => setFilter("all")}
                      >
                          Alle Bewertungen
                      </a>
                      <a
                          href="#"
                          className={filter === "approved" ? "active" : ""}
                          onClick={() => setFilter("approved")}
                      >
                          Veröffentlicht
                      </a>
                      <a
                          href="#"
                          className={filter === "declined" ? "active" : ""}
                          onClick={() => setFilter("declined")}
                      >
                          Abgelehnt
                      </a>
                  </Col>
              </Row>
              <Row className="gx-3 reviewsContent mb-3">
                  {filteredReviews().length > 0 ? (
                      filteredReviews().map((review) => (
                          <Col className="mb-3" xl="3" key={review.id}>
                              <div>
                                  <div className="rUsrImage"></div>
                                  <p className="rUsrName">{review.name}</p>
                                  <p className="rDate">&nbsp;</p>
                                  <p className="ratingNumber">
                                      {Array.from({ length: review.ratings }, (_, index) => (
                                          <span key={index}>&#9733;</span> // Unicode star symbol
                                      ))}
                                  </p>
                                  <p className="rComment">{review.review}</p>
                                  <ul className="reviewsBtn">
                                  {review.ratingStatus === "Approved" ? (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Approved")}
            className="approveBtn"
            style={{ opacity: 0.5, pointerEvents: "none" }} // Apply styles for disabled button
        >
            Approve
        </button>
    </li>
) : (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Approved")}
            className="approveBtn"
        >
            Approve
        </button>
    </li>
)}

{review.ratingStatus === "Declined" ? (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Declined")}
            className="declineBtn"
            style={{ opacity: 0.5, pointerEvents: "none" }} // Apply styles for disabled button
        >
            Decline
        </button>
    </li>
) : (
    <li>
        <button
            onClick={() => handleStatusChange(review.surveyId, review.id, "Declined")}
            className="declineBtn"
        >
            Decline
        </button>
    </li>
)}
                                  </ul>
                              </div>
                          </Col>
                      ))
                  ) : (
                      <p>No reviews available.</p>
                  )}
              </Row>
              {/* Load more button */}
              {hasMore && (
                  <Row>
                      <Col className="text-center">
                          <button onClick={() => fetchReviews(true)} disabled={loading}>
                              {loading ? "Loading..." : "Load More"}
                          </button>
                      </Col>
                  </Row>
              )}
          </Container>
      </>
  );
};

export default Reviews;