import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { addQuestion } from "../../services/surveyService";
import AddNewUserBg from '../../assets/img/theme/dashboardTopContentBg.png';

const AddQuestionModal = ({ show, handleClose, surveyId, setQuestions }) => {
  const [questionText, setQuestionText] = useState("");
  const [answers, setAnswers] = useState(["", "", "", ""]);

  const divStyle = {
    backgroundImage: `url(${AddNewUserBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '40px',
  };

  const handleQuestionChange = (e) => {
    setQuestionText(e.target.value);
  };

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  const handleAddAnswer = () => {
    setAnswers([...answers, ""]);
  };

  const handleSave = async () => {
    const newQuestion = { question: questionText, answers, isActive: true, isMultipleChoice: false, surveyId, order: 0 };
    const docRef = await addQuestion(surveyId, newQuestion);
    const updatedQuestion = { id: docRef.id, ...newQuestion };

    setQuestions((prevQuestions) => [...prevQuestions, updatedQuestion]);
    handleClose();
  };

  return (
    <Modal className="addNewUserModal editQuestionModal  addNewWalletModal" show={show} onHide={handleClose}>
      <Modal.Header style={divStyle}>
        <Modal.Title>Neue Frage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Frage</Form.Label>
            <Form.Control type="text" value={questionText} onChange={handleQuestionChange} />
          </Form.Group>
          <Form.Label>Antworten</Form.Label>
          {answers.map((answer, index) => (
            <Form.Group key={index}>
              <Form.Control
                type="text"
                value={answer}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
              />
            </Form.Group>
          ))}
          <Button variant="secondary" onClick={handleAddAnswer}>
            Add Answer
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        Verwerfen
        </Button>
        <Button variant="primary" onClick={handleSave}>
        Frage erstellen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddQuestionModal;