// src/services/firebaseService.js
import { doc, getDoc, getDocs, collection, updateDoc, deleteDoc  } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase-config";
import { format } from "date-fns";

export async function fetchUserById(userId) {
  const userDoc = doc(db, "users", userId);
  const userSnapshot = await getDoc(userDoc);
  if (userSnapshot.exists()) {
    return { id: userSnapshot.id, ...userSnapshot.data() };
  } else {
    throw new Error("User not found");
  }
}

export function formatDate(timestamp) {
  if (timestamp && timestamp.toDate) {
    const date = timestamp.toDate();
    return format(date, "dd.MM.yyyy"); // Adjust the format as needed
  }
  return "";
}

export async function fetchWalletHistory(userId) {
  const walletHistoryCollection = collection(db, "users", userId, "tradingHistory");
  const walletHistorySnapshot = await getDocs(walletHistoryCollection);

  return walletHistorySnapshot.docs.map(doc => {
    const data = doc.data();

    return {
      id: doc.id,
      createdAt: formatDate(data.createdAt), 
      tradingValue: data.tradingValue,
      coinData: data.coinData || {},          
    };
  });
}

export async function fetchDocuments(userId) {
  const walletDocuments = collection(db, "users", userId, "documents");
  const walletDocumentSnapshot = await getDocs(walletDocuments);

  return Promise.all(
    walletDocumentSnapshot.docs.map(async (doc) => {
      const data = doc.data();
      const storagePath = data.path;
      let downloadURL = "";
      
      try {
        const storageRef = ref(storage, storagePath); 
        downloadURL = await getDownloadURL(storageRef); 
      } catch (error) {
        console.error("Error fetching file URL:", error);
      }

      return {
        id: doc.id,
        createdAt: formatDate(data.createdAt),
        title: data.title,
        path: downloadURL, 
      };
    })
  );
}
export async function fetchWallet(userId) {
  const walletCollection = collection(db, "users", userId, "wallet");
  const walletSnapshot = await getDocs(walletCollection);
  return walletSnapshot.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      createdAt: formatDate(data.createdAt),
      cryptoValue: data.cryptoValue,
      symbol: data.coinData?.symbol || "Unknown",
    };
  });
}

export async function deleteWalletEntry(userId, entryId) {
  if (typeof entryId !== "string") {
    console.error("Invalid entryId:", entryId);
    return;
  }
  const entryRef = doc(db, "users", userId, "wallet", entryId);
  await deleteDoc(entryRef);
}

export async function updateWalletEntry(userId: string, entryId: string, newValue: any) {
  const entryRef = doc(db, "users", userId, "wallet", entryId);
  const cryptoValue = Number(newValue);  // Convert newValue to number

  // Update document with the new crypto value
  await updateDoc(entryRef, {
    cryptoValue: cryptoValue,
  });
}
