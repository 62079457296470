
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
  } from "reactstrap";
  
  import React, { useState, useEffect } from "react";
  import { db } from "../firebase-config";
  import { collection, getDocs, addDoc, query, orderBy, onSnapshot, serverTimestamp } from "firebase/firestore";
  import Header from "components/Headers/Header.js";
  import ChatList from "components/Chat/ChatList";
  import MessageList from "components/Chat/ChatPage";
  import NewMessageForm from "components/Chat/NewMessageForm";

  const Support = () => {
    const [selectedChatId, setSelectedChatId] = useState(null);
    const adminId = "adminId"; // Replace this with your actual adminId
    const userId = "user123"; // Replace this with the actual user ID
    const handleSelectChat = (chatId) => {
        setSelectedChatId(chatId);
    };
    return (
      <>
       <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
        <h3 className="text-white text-xl mb-4">Unterhaltung</h3>
        <div className="">
          <Row>
            <Col xl="4">
            <div className="chatSections card-bgColor">
            <ChatList onSelectChat={handleSelectChat} />
            </div>
            </Col>
            <Col xl="8">
            <div className="chatSections card-bgColor">
            <MessageList chatId={selectedChatId} />
            {selectedChatId && <NewMessageForm chatId={selectedChatId} senderId={adminId} />}
            </div>
            </Col>
          </Row>
          
          </div>
        </Container>
      </>
    );
  };
  
  export default Support;
  