import React, { useState } from "react";
import { getFirestore, collection, addDoc, doc, Timestamp  } from "firebase/firestore";
import { walletDataArray } from "../views/walletData";
// Firestore setup
const db = getFirestore();

const insertMultipleWalletData = async (targetUserId, walletDataArray) => {
    // Check if walletDataArray is defined and is an array
    if (!Array.isArray(walletDataArray)) {
      console.error('Error: walletDataArray is not an array or is undefined:', walletDataArray);
      return; // Exit early if there's an issue
    }
  
    try {
      const walletRef = collection(doc(db, "users", targetUserId), "wallet");
  
      // Process and insert each wallet data
      const insertPromises = walletDataArray.map(async (walletData) => {
        const { cryptoValue, ...rest } = walletData;
  
        // Convert cryptoValue to number and add createdAt/updatedAt as Firestore Timestamps
        const processedWalletData = {
          ...rest,
          cryptoValue: parseFloat(cryptoValue), // Ensure cryptoValue is a number
          createdAt: Timestamp.now(), // Insert current timestamp for createdAt
          updatedAt: Timestamp.now(), // Insert current timestamp for updatedAt
        };
  
        // Insert into Firestore
        await addDoc(walletRef, processedWalletData);
      });
  
      await Promise.all(insertPromises);
      alert("All data inserted successfully into wallet subcollection!");
    } catch (error) {
      console.error("Error inserting data into wallet:", error);
    }
  };
  
  const InsertWalletData = () => {
    const [userId, setUserId] = React.useState("");
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (userId) {
        // Pass userId and walletDataArray to insert function
        insertMultipleWalletData(userId, walletDataArray);
      } else {
        alert("Please enter a valid User ID");
      }
    };

  return (
    <div>
      <h2>Insert Wallet Data to User</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="userId">User ID:</label>
          <input
            type="text"
            id="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="Enter User ID"
          />
        </div>
        <button type="submit">Insert Data</button>
      </form>
    </div>
  );
};

export default InsertWalletData;
