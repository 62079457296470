import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";


import { AuthContextProvider } from "context/AuthContext";
import App from "./App"
const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
    <AuthContextProvider>
    <App />
  </AuthContextProvider>
);
