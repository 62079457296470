import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config"; // Adjust this path based on your project structure

// Function to fetch wallet data
export const fetchWalletData = async (userId) => {
  const walletRef = collection(db, `users/${userId}/wallet`);
  const walletSnapshot = await getDocs(walletRef);
  
  return walletSnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
};

// Function to fetch coin prices
export const fetchCoinsData = async () => {
  const coinsRef = collection(db, "coins");
  const coinsSnapshot = await getDocs(coinsRef);
  
  const coins = {};
  coinsSnapshot.docs.forEach((doc) => {
    const coinData = doc.data();
    coins[coinData.symbol] = coinData.price; // Assuming each coin doc has a `symbol` and `currentPrice` field
  });

  return coins;
};

// Function to fetch trading history data
export const fetchTradingHistory = async (userId) => {
  const tradingHistoryRef = collection(db, `users/${userId}/tradingHistory`);
  const tradingHistorySnapshot = await getDocs(tradingHistoryRef);
  
  return tradingHistorySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
};

export const calculateTotalBalance = (walletData, coinsData, totalTrading) => {
  let totalBalance = 0;
  
  // Calculate balance based on wallet data
  walletData.forEach((coin) => {
    const coinPrice = coinsData[coin.coinData.symbol];
    if (coinPrice) {
      totalBalance += coin.cryptoValue * coinPrice;
    }
  });

  // Add totalTrading to the balance
  totalBalance += totalTrading;

  return totalBalance;
};

// Function to format a number like 20'200.09
export const formatBalance = (balance) => {
  return new Intl.NumberFormat('en-CH', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  }).format(balance);
};
