import React, { useEffect, useState } from "react";
import axios from "axios";

const BackendTest = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      axios
        .get("http://localhost:5000/api/users")  // API call to backend
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    }, []);
  
    return (
      <div>
        <h1>Users List</h1>
        <ul>
          {users.map((user) => (
            <li key={user.id}>
              {user.name} ({user.email})
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default BackendTest;
