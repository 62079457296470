import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useContext } from "react";
import UserProfile from "views/UserProfile";

function App() {
  
    const {currentUser} = useContext(AuthContext)
  
    const RequireAuth = ({ children }) => {
      return currentUser ? children : <Navigate to="/auth/login" />;
    };
   console.log(currentUser);
    return (
        <BrowserRouter>
        <Routes>
        <Route 
        path="/admin/*" 
        element={
        <RequireAuth>
                <AdminLayout />
        </RequireAuth>
        } 
        
        />
        <Route 
        path="/profile/:id" 
        element={<UserProfile />} 
        />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
        </BrowserRouter>
    );
}

export default App;


