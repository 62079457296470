// src/components/ChatList.js
import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";

const ChatList = ({ onSelectChat }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true); // To show loading state
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const chatCollection = collection(db, "chat");
        const chatSnapshot = await getDocs(chatCollection);
        const chatList = [];

        for (const chatDoc of chatSnapshot.docs) {
          const messagesCollection = collection(chatDoc.ref, "messages");
          const messagesSnapshot = await getDocs(messagesCollection);

          if (!messagesSnapshot.empty) {
            chatList.push({ id: chatDoc.id, ...chatDoc.data() });
          }
        }

        console.log("Fetched chats with messages: ", chatList);
        setChats(chatList);
      } catch (err) {
        console.error("Error fetching chats: ", err);
        setError("Failed to fetch chats");
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, []);

  if (loading) return <div>Loading chats...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="chat-list">
      <ul className="listOfChats">
        {chats.map(chat => (
          <li key={chat.id} onClick={() => onSelectChat(chat.id)}>
            {chat.userName || "Unnamed Chat"}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatList;
