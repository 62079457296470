import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { signInWithEmailAndPassword  } from "firebase/auth";
import { auth, db } from '../firebase-config';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { doc, getDoc } from "firebase/firestore";
const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const {dispatch} = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Reference to the user's document in Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
  
        // Check if the user's role is 'Admin'
        if (userData.role === "Admin") {
          dispatch({ type: "LOGIN", payload: user });
          navigate("/");
        } else {
          setError("You do not have admin privileges.");
        }
      } else {
        setError("User document does not exist.");
      }
    } catch (error) {
      console.error("Error during login: ", error);
      setError(true);
    }
  };


  return (
    <>
      <Col lg="5" md="7">
        <Card className="sidebar-bgColor shadow border-0">
         
          <CardBody className="px-lg-5 py-lg-5">
           
            <Form role="form" onSubmit={handleLogin}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
             
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
              {error && <span>Wrong email or password!</span>}
            </Form>

          </CardBody>
        </Card>
       
      </Col>
    </>
  );
};

export default Login;
