// src/components/NewMessageForm.js
import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase-config";
import sendIcon from "../../assets/img/icons/Send.png";

const NewMessageForm = ({ chatId, senderId }) => {
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (message.trim() && chatId) {
            try {
                await addDoc(collection(db, `chat/${chatId}/messages`), {
                    message: message,
                    senderId: senderId,
                    time: serverTimestamp()
                });
                setMessage(""); // Clear the input field
            } catch (error) {
                console.error("Error adding message: ", error);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setMessage((prevMessage) => prevMessage + "\n");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="new-message-form">
            <textarea
                rows="2"
                cols="50"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type your message..."
            />
            <button type="submit"><img src={sendIcon}/></button>
        </form>
    );
};

export default NewMessageForm;
