
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const Header = () => {
  return (
    <>
      <div className="header pb-8 pt-5">
        <Container fluid>
          <div className="header-body">
           
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
