// src/EditForm.js
import React, { useState } from "react";
import { updateQuestion } from "../../services/surveyService";

const EditForm = ({ question, setQuestions, questions, setEditingQuestion, handleClose }) => {
    const [newQuestion, setNewQuestion] = useState(question.question);
    const [newAnswers, setNewAnswers] = useState(question.answers);
  
    const handleQuestionChange = (e) => {
      setNewQuestion(e.target.value);
    };
  
    const handleAnswersChange = (index, value) => {
      const updatedAnswers = [...newAnswers];
      updatedAnswers[index] = value;
      setNewAnswers(updatedAnswers);
    };
  
    const handleSave = async () => {
      const updatedQuestion = { ...question, question: newQuestion, answers: newAnswers };
      await updateQuestion(question.surveyId, question.id, updatedQuestion);
      setQuestions(questions.map(q => (q.id === question.id ? updatedQuestion : q)));
      setEditingQuestion(null);
      handleClose();
    };
  
    return (
      <div>
        <label>Frage
        <input 
          type="textarea" 
          value={newQuestion} 
          onChange={handleQuestionChange} 
        />
        </label>
        <ul>
          <label>Antworten</label>
          {newAnswers.map((answer, index) => (
            <li key={index}>
              <input 
                type="text" 
                value={answer} 
                onChange={(e) => handleAnswersChange(index, e.target.value)} 
              />
            </li>
          ))}
        </ul>
        <div className="actionButtons">
        <button onClick={handleClose}>Verwerfen</button>
        <button onClick={handleSave} className="saveButton">Speichern</button>
        </div>
        
      </div>
    );
  };
  
  export default EditForm;
