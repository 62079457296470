import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, collection, addDoc, getDocs, deleteDoc, Timestamp, onSnapshot  } from "firebase/firestore";
import { db } from "../firebase-config";
import Header from "components/Headers/Header";
import {
  Badge,
  Card,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Dropdown,
} from "reactstrap";
import { format } from "date-fns";
import { flattenUserData, formatInvestmentDate } from "../utils/userUtils";
import { deleteWalletEntry, updateWalletEntry, fetchWalletHistory, fetchDocuments, fetchWallet } from "../services/firebaseService";
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button, Modal } from 'react-bootstrap';
import AddNewUserBg from '../assets/img/icons/modalHeaderBg.png'; 
import trashIcon from '../assets/img/icons/trash.png';   
import pdfIcon from '../assets/img/icons/pdf.png';   
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { addTradingHistory, editTradingHistory, deleteTradingHistory } from "../services/tradingHistoryService";
import { fetchWalletData, fetchCoinsData, fetchTradingHistory, calculateTotalBalance, formatBalance  } from "../utils/walletUtils";
import Accordion from "components/Accordion/Accordion";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import axios from "axios"; 
import FileManagement from '../components/UsersDocument/UserFiles';


const UserProfile = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [telefonNumer, setTelefonNumer] = useState("");
  const [investmentDate, setInvestmentDate] = useState("");
  const [startKapital, setStartKapital] = useState("");
  const [goalCapital, setGoalCapital] = useState("");
  const [goalDate, setGoalDate] = useState("");
  const [walletHistory, setWalletHistory] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [show, setShow] = useState(false);
  const [showWall, setShowWall] = useState(false);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [coins, setCoins] = useState([]);
  const [newEntryProfit, setNewEntryProfit] = useState("");
  const [editingEntry, setEditingEntry] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCoins, setFilteredCoins] = useState(coins);
  const [role, setRole] = useState("Kunde");
  const [balance, setBalance] = useState(0);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [showDoc, setShowDoc] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(""); // State for the selected coin
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [WalletOfUser, setWalletOfUser] = useState([]);

  /* Fields for add document */
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [type, setType] = useState('PDF');
  const [seen, setSeen] = useState(false);
  const [message, setMessage] = useState('');

  const fetchDataAndCalculateBalance = async () => {
    try {
      // Fetch wallet data and user document
      const walletData = await fetchWalletData(id);
      const userDocRef = doc(db, "users", id);
      const userSnapshot = await getDoc(userDocRef);
  
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const totalTrading = userData.totalTrading || 0; // Retrieve totalTrading from the user document
  
        const coinsData = await fetchCoinsData();
        const totalBalance = calculateTotalBalance(walletData, coinsData, totalTrading); // Pass totalTrading directly
        const formattedBalance = formatBalance(totalBalance);
  
        setBalance(formattedBalance); // Set the calculated balance
      } else {
        console.error("User document does not exist");
        toast.error("User document not found.");
      }
    } catch (error) {
      console.error("Error fetching data or calculating balance:", error);
      toast.error("Error calculating balance: " + error.message);
    }
  };

 // Fetching user data
 useEffect(() => {
  const fetchUserData = async () => {
    try {
      const userDoc = doc(db, "users", id);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        setUser(userData);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  fetchUserData();
}, [id]);

useEffect(() => {
  const fetchWalletDataForUser = () => {
    const walletCollection = collection(db, "users", id, "wallet");
    
    // Real-time listener with onSnapshot
    const unsubscribe = onSnapshot(walletCollection, async (walletSnapshot) => {
      const coinsCollection = collection(db, "coins");
      const coinsSnapshot = await getDocs(coinsCollection);

      const coinPrices = coinsSnapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        acc[data.symbol.toUpperCase()] = data.price;
        return acc;
      }, {});

      const walletData = walletSnapshot.docs.map(doc => {
        const data = doc.data();
        const symbol = data.coinData?.symbol || "Unknown";
        const cryptoValue = parseFloat(data.cryptoValue) || 0;
        const price = coinPrices[symbol.toUpperCase()] || 0;
        const order = data.coinData?.order || 0;

        return {
          id: doc.id,
          symbol: symbol,
          valueInCHF: cryptoValue * price,
          order: order,
        };
      });

      const sortedWalletData = walletData.sort((a, b) => a.order - b.order);
      setWalletOfUser(sortedWalletData);
    });

    return () => unsubscribe(); // Cleanup on unmount
  };

  fetchWalletDataForUser();
}, [id]);



  

  
  useEffect(() => {
       fetchDataAndCalculateBalance();
  }, [id]);

  const handleClose = () => {
    setShow(false);
    setEditingEntry(null);
    setNewEntryProfit("");
  };
  const handleShowDoc = () => setShowDoc(true);
  const handleShow = async () => {
    try {
      const coinsCollection = collection(db, "coins"); // Firestore collection
      const coinsSnapshot = await getDocs(coinsCollection); // Fetch documents
      const coinsList = coinsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        currency: doc.id, // Include the document ID as a currency field
      }));

      console.log("Fetched coins:", coinsList); // For debugging

      setCoins(coinsList); // Set coins state
      setShow(true); // Show modal
    } catch (error) {
      console.error("Error fetching coins:", error); // Error handling
    }
  };
  const handleCloseWall = () => setShowWall(false);
  const handleCloseDoc = () => setShowDoc(false);
  const handleShowWall = async () => {
    try {
      const coinsCollection = collection(db, "coins");
      const coinsSnapshot = await getDocs(coinsCollection);
      const coinsList = coinsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        currency: doc.id,
      }));
      setCoins(coinsList);
      setShowWall(true);
    } catch (error) {
      console.error("Error fetching coins: ", error);
    }
  };
  const handleCoinChange = (e) => {
    setSelectedCoin(e.target.value); // Update the selected coin
  };
  const divStyle = {
    backgroundImage: `url(${AddNewUserBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '40px 0',
    color: 'white', // Ensure the text is readable over the background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const renderCoinDropdown = () => {
    return (
      <select value={selectedCoin} onChange={(e) => setSelectedCoin(e.target.value)} required>
      <option value="">Select a coin</option>
      {coins.map((coin) => (
        <option key={coin.currency} value={coin.currency}>
          {coin.name} ({coin.symbol.toUpperCase()})
        </option>
      ))}
    </select>
    );
  };
  const [newEntryDate, setNewEntryDate] = useState("");
  const [newEntryProfitInPercentage, setNewEntryProfitInPercentage] = useState("");
  const [newEntryWalletState, setNewEntryWalletState] = useState("");
  const [newEntryCryptoValue, setNewEntryCryptoValue] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = doc(db, "users", id);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          const flattenedUserData = flattenUserData(userData);

          setUser(userData);
          setName(userData.name || ""); 
          setEmail(userData.email || ""); 
          setDob(flattenedUserData.dob || ""); 
          setTelefonNumer(userData.phone || ""); 
          setInvestmentDate(flattenedUserData.investmentDate || ""); 
          setStartKapital(flattenedUserData.startKapital || ""); 
          setGoalCapital(flattenedUserData.goalCapital || ""); 
          setGoalDate(flattenedUserData.goalDate || ""); 
          
          setRole(userData.role || "Kunde");

          const walletHistoryData = await fetchWalletHistory(id);
          setWalletHistory(walletHistoryData);

          const walletData = await fetchWallet(id);
          // setWallet(walletData);

          const initialValues = {};
          walletData.forEach((item) => {
            initialValues[item.id] = item.cryptoValue;
          });
          setInputValues(initialValues);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  const handleSave = async () => {
    try {
      const userDoc = doc(db, "users", id);
  
      const userSnapshot = await getDoc(userDoc);
      if (!userSnapshot.exists()) {
        toast.error("User data not found.");
        return;
      }
  
      const convertToDateObj = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        return new Date(`${year}-${month}-${day}`);
      };
  
      let convertedInvestmentDate = null;
      let convertedGoalDate = null;
  
      if (investmentDate) {
        const investmentDateObj = convertToDateObj(investmentDate);
        if (!isNaN(investmentDateObj.getTime())) {
          convertedInvestmentDate = Timestamp.fromDate(investmentDateObj);
        } else {
          toast.error("Invalid investment date.");
          return;
        }
      }
  
      if (goalDate) {
        const goalDateObj = convertToDateObj(goalDate);
        if (!isNaN(goalDateObj.getTime())) {
          convertedGoalDate = Timestamp.fromDate(goalDateObj);
        } else {
          toast.error("Invalid goal date.");
          return;
        }
      }
  
      await updateDoc(userDoc, {
        name,
        email,
        telefonNumer,
        "investmentDetails.startCapital": startKapital,  
        "investmentDetails.goalCapital": goalCapital,  
        "investmentDetails.investmentDate": convertedInvestmentDate, 
        "investmentDetails.goalDate": convertedGoalDate, 
        role,
      });
  
      toast.success("Profildaten erfolgreich aktualisiert.");
    } catch (error) {
      toast.error("Error saving data: " + error.message);
    }
  };
  

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleCheckboxChange = (currency) => {
    setSelectedCurrencies((prev) =>
      prev.includes(currency)
        ? prev.filter((item) => item !== currency)
        : [...prev, currency]
    );
  };

  const handleAddWallet = async (e) => {
    e.preventDefault();
    try {
      const walletCollection = collection(db, "users", id, "wallet");
  
      await Promise.all(
        selectedCurrencies.map(async (currency) => {
          const selectedCoin = coins.find(coin => coin.currency === currency);
          if (!selectedCoin) {
            toast.error(`Coin data for currency ${currency} not found`);
            return;
          }
  
          const newEntry = {
            createdAt: new Date(),
            updatedAt: new Date(),
            coinData: {
              color: selectedCoin.color || "defaultColor", 
              isFavorite: selectedCoin.isFavorite || false,
              order: selectedCoin.order !== undefined ? selectedCoin.order : 0, 
              symbol: selectedCoin.symbol || "unknown"
            },
            cryptoValue: 0, // Set default value to 0
          };
  
          // Just add the entry to Firestore, do not update local state manually
          await addDoc(walletCollection, newEntry);
        })
      );
  
      toast.success("Neuer Gewinn erfolgreich hinzugefügt.");
      setSelectedCurrencies([]); // Clear selected currencies
      setShowWall(false); // Close the modal
  
    } catch (error) {
      toast.error("Error adding wallet entries: " + error.message);
    }
  };
  
  
  

  const handleDelete = async (entryId) => {
    if (typeof entryId !== "string") {
      toast.error("Invalid entry ID");
      return;
    }
  
    try {
      // Call the delete function to remove the entry from the database
      await deleteWalletEntry(id, entryId);
  
      // Remove the entry from the local WalletOfUser state
      setWalletOfUser(WalletOfUser.filter(item => item.id !== entryId));
      fetchDataAndCalculateBalance();
      toast.success("Kryptowährung erfolgreich gelöscht.");
    } catch (error) {
      toast.error("Error deleting entry: " + error.message);
    }
  };
  
// Handle the change in the input field (without affecting the displayed CHF value)
const handleChange = (entryId, newValue) => {
  setInputValues(prevValues => ({
    ...prevValues,
    [entryId]: { value: newValue }, // Only update the specific input field with new user-entered value
  }));
};

// Handle saving the updated value and updating the database
const handleBlur = async (entryId, newValueInCHF) => {
  if (!newValueInCHF || newValueInCHF.trim() === "") {
    return; // Exit if no value is entered
  }

  try {
    // Find the wallet entry
    const walletEntry = WalletOfUser.find(item => item.id === entryId);
    const coinSymbol = walletEntry?.symbol;

    if (!coinSymbol) {
      toast.error("Coin symbol not found.");
      return;
    }

    // Fetch the coin price from the coins collection
    const coinsCollection = collection(db, "coins");
    const coinSnapshot = await getDocs(coinsCollection);
    const coinData = coinSnapshot.docs.find(doc => doc.data().symbol.toUpperCase() === coinSymbol.toUpperCase());
    const coinPrice = coinData?.data()?.price;

    if (!coinPrice) {
      toast.error(`Price for ${coinSymbol} not found.`);
      return;
    }

    // Perform the calculation: CHF value / coin price = crypto value
    const calculatedCryptoValue = parseFloat(newValueInCHF) / coinPrice;

    // Update the backend (wallet collection) with the calculated cryptoValue
    await updateWalletEntry(id, entryId, calculatedCryptoValue);

    // Update the WalletOfUser state to reflect the new CHF value for display
    setWalletOfUser(WalletOfUser.map((item) =>
      item.id === entryId ? { ...item, valueInCHF: parseFloat(newValueInCHF) } : item
    ));
    fetchDataAndCalculateBalance();
    toast.success("Eintrag erfolgreich aktualisiert.");
  } catch (error) {
    console.error("Error updating wallet entry:", error);
    toast.error("Error updating entry: " + error.message);
  }
};


  

  const handleProfitSave = async (e) => {
    e.preventDefault();
  
    // Find the selected coin from the coins array by its 'currency' field (document ID)
    const selectedCoinData = coins.find(coin => coin.currency === selectedCoin);
  
    // If no coin data is found, exit early
    if (!selectedCoinData) {
      console.error("Selected coin data not found");
      return;
    }
  
    // Create the coinData object in the desired format
    const coinData = {
      symbol: selectedCoinData.symbol,
      color: selectedCoinData.color,
      isFavorite: selectedCoinData.isFavorite || false,
      order: selectedCoinData.order || 0,
    };
  
    // Call the appropriate save function
    const success = editingEntry
      ? await editTradingHistory(id, editingEntry.id, newEntryProfit, coinData) // Editing entry
      : await addTradingHistory(id, newEntryProfit, coinData); // Adding new entry
  
    if (success) {
      const walletHistoryData = await fetchWalletHistory(id); // Fetch updated data
      setWalletHistory(walletHistoryData); // Update the wallet history state
      handleClose(); // Close the modal
      fetchDataAndCalculateBalance(); // Recalculate the balance
    }
  };
  const coinTemplate = (rowData) => {
    // Check if coinData exists in the rowData
    const coinData = rowData.coinData;
  
    // If coinData is available, display the coin symbol
    return coinData ? (
      <span>{coinData.symbol}</span>
    ) : (
      <span>Unknown</span>
    );
  };
  
  const handleEditClick = (entry) => {
    setEditingEntry(entry);
    setNewEntryProfit(entry.tradingValue);
    setShow(true);
  };

  const handleDeleteClick = async (entryId) => {
    const success = await deleteTradingHistory(id, entryId);
    if (success) {
      setWalletHistory(walletHistory.filter(entry => entry.id !== entryId));
      fetchDataAndCalculateBalance();
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
       <button onClick={() => handleEditClick(rowData)} className="editProfitBtn">
       Bearbeiten
                    </button>
        <button onClick={() => handleDeleteClick(rowData.id)} className="deleteProfitBtn">
                      <img src={trashIcon} alt="Delete"/>
                    </button>
      </React.Fragment>
    );
  };
  const tradingValueTemplate = (rowData) => {
    const value = rowData.tradingValue;
    const className = value >= 0 ? 'positive' : 'negative';
    
    return (
      <span className={className}>
        {value}
      </span>
    );
  };

  const onSelectionChange = (currency) => {
    handleCheckboxChange(currency);
  };

  const symbolBodyTemplate = (rowData) => {
    return (
      <div>
        <Checkbox
          inputId={rowData.symbol}
          value={rowData.currency}
          checked={selectedCurrencies.includes(rowData.currency)}
          onChange={() => onSelectionChange(rowData.currency)}
        />
        <label htmlFor={rowData.symbol} style={{ marginLeft: '8px' }}>
        <img
        src={rowData.logo}
        alt={rowData.symbol}
        style={{ width: '20px', height: '20px', marginRight: '30px' }}
      />
          <span>{rowData.symbol}</span>
          <span>{rowData.name}</span>
        </label>
      </div>
    );
  };

  useEffect(() => {
    const filterAndSortCoins = () => {
      // Filter the coins based on the searchValue if it's present
      const filteredCoins = searchValue
        ? coins.filter(coin => {
            const symbol = coin.symbol ? coin.symbol.toLowerCase() : "";
            const name = coin.name ? coin.name.toLowerCase() : "";
            return (
              symbol.includes(searchValue.toLowerCase()) ||
              name.includes(searchValue.toLowerCase())
            );
          })
        : [...coins]; // Spread to avoid mutating the original array
  
      // Sort by the `order` field from the `coins` collection
      filteredCoins.sort((a, b) => (a.order || 0) - (b.order || 0));
  
      setFilteredCoins(filteredCoins);
    };
  
    filterAndSortCoins();
  }, [searchValue, coins]);
  


  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
  
    // Log the userId to ensure it's correct
    console.log("Resetting password for userId:", id);
  
    try {
      const response = await axios.post(
        '/api/users/{id}/documents',
        {
          userId: id,  // The user ID obtained from useParams
          newPassword: newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        toast.success("Passwort erfolgreich zurückgesetzt!");
        setShowResetPasswordModal(false);  // Close modal after success
      } else {
        toast.error("Failed to reset password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("Failed to reset password. " + (error.response?.data?.error || error.message));
    }
  };
  if (!user) return <div>Loading...</div>;

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <div className="card-bgColor portfolioUserSection">
        <Row className="">
          <Col xl="8"><h2>Benutzerportfolio</h2>  </Col>
          <Col className="text-white text-right">Gesamtwert des Portfolios: <span className="totPortfolio">{balance} CHF</span></Col>
          </Row>
          
          <Row style={{ display: "flex" }}>
            <Col style={{ flex: "0 0 38%" }}>
              <Row>
                <Col>
                  <label>
                    Name / Vorname:
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </Col>
                <Col>
                <label>
                  Rolle:
                  <Input
                    type="select"
                    id="role"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    <option value="Admin">Admin</option>
                    <option value="Kunde">Kunde</option>
                  </Input>
                </label>
              </Col>
              </Row>
              <Row>
                <Col>
                  <label>
                    Email:
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                </Col>
                <Col>
                  <label>
                    Telefonnumer:
                    <input
                      type="text"
                      value={telefonNumer}
                      onChange={(e) => setTelefonNumer(e.target.value)}
                    />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col style={{ flex: "0 0 38%" }}>
              <Row>
                <Col>
                  <label>Investitionsdatum:
                    <input type="text" value={investmentDate}
                    onChange={(e) => setInvestmentDate(e.target.value)} /></label>
                </Col>
                <Col>
                  <label className="inputForChf">Start Kapital:
                    <input type="number" value={startKapital}
                    onChange={(e) => setStartKapital(e.target.value)} /></label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="inputForChf">ZIEL:
                    <input type="text" value={goalCapital} 
                    onChange={(e) => setGoalCapital(e.target.value)} /></label>
                </Col>
                <Col>
                  <label>Zieldatum:
                    <input type="text" value={goalDate} 
                    onChange={(e) => setGoalDate(e.target.value)} /></label>
                </Col>
              </Row>
            </Col>
            <Col style={{ flex: "0 0 24%" }}>
            <Button variant="primary" className="resetPButton" onClick={() => setShowResetPasswordModal(true)}>
            Passwort zurücksetzen
        </Button>
            </Col>
          </Row>
          <Row className="justify-end">
          <button onClick={handleSave}>Aktualisierung</button>

         
          </Row>
        </div>
       
        <div className="card-bgColor portfolioUserWallet">
          <Row>
            <Col xl="5">
              <h2>Kryptowährungsliste</h2>
              <ul style={{ width: "100%", listStyleType: "none", padding: 0 }} className="cryptoListProfile">
              {WalletOfUser.map((item) => (
                <li key={item.id} style={{ display: "flex", justifyContent: "space-between", padding: "8px 0", alignItems: "center" }}>
                  <span className={item.symbol}>{item.symbol}</span>
                
                  {item.valueInCHF && (
                    <span className="chfValueOfCoin">
                      {item.valueInCHF.toFixed(2)}
                    </span>
                  )}
                  <span>
                <input
                  type="number"
                  placeholder="Neuen Wert eingeben"
                  value={inputValues[item.id]?.value || ""} // Keep the input empty initially
                  onChange={(e) => handleChange(item.id, e.target.value)}  // Update input value
                  onBlur={(e) => handleBlur(item.id, e.target.value)}  // Process the value on blur
                  style={{ width: "100%" }}
                />
              </span>
              <button onClick={() => handleDelete(item.id)}>
                <img src={trashIcon} alt="Delete"/>
              </button>
              {/* Display the calculated CHF value under the li */}
              
                </li>
              ))}
            </ul>



              <div className="addNewCrypto"><button onClick={handleShowWall}>Neue Krypto hinzufügen</button></div>
            </Col>
            <Col xl="7">
              <h2>Wallet-Verlauf</h2>
              <a href="#" className="addNewData" onClick={handleShow}>+ Neue Daten hinzufügen</a>

              <div className="walletHistoryTable">
                <DataTable value={walletHistory} paginator rows={5} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="createdAt" header="Datum" style={{ width: '25%' }}></Column>
                  <Column field="tradingValue" header="Gewinn in CHF" body={tradingValueTemplate} style={{ width: '25%' }}></Column>
                  <Column header="Coin" body={coinTemplate} style={{ width: '20%' }}></Column>
                  <Column body={actionBodyTemplate} header="Aktionen" style={{ width: '30%' }}></Column>
                </DataTable>
              </div>


            </Col>
          </Row>
        </div>
        <div className="card-bgColor portfolioUserWallet">
          <Row>
            <Col>
            <FileManagement  userId={id} />
            </Col>
          </Row>
         

        </div>


        <Modal className="addNewUserModal addCoinsToUser addNewWalletModal" show={showWall} onHide={handleCloseWall}>
          <Modal.Header style={divStyle}>
            <Modal.Title>Kryptowährungsliste</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form onSubmit={handleAddWallet}>
          <div className="coinsUserTable">
          <div style={{ marginBottom: '1em' }}>
        <InputText
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Suche"
        />
      </div>
              <DataTable
               value={filteredCoins}
               scrollable
               scrollHeight="770px"
               tableStyle={{ paddingRight: '10px' }} 
              >
                <Column
                  field="symbol"
                  header=""
                  body={symbolBodyTemplate}
                />
              </DataTable>
              </div>
              <Row className="cryptoLsitModalButton">
                <Col>
                  <span onClick={handleCloseWall} className="dicardChng">Verwerfen</span>
                </Col>
                <Col>
                  <Button type="submit" className="saveChanges">Auswählen</Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
        <Modal className="addNewUserModal addNewWalletModal" show={show} onHide={handleClose}>
          <Modal.Header style={divStyle}>
            <Modal.Title>{editingEntry ? "Edit Wallet-Daten" : "Neue Wallet-Daten"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleProfitSave} className="addNewWHistoryFrm">
              <Row>
                <Col>
                  <div>
                    <label>Gewinn in CHF:
                      <input type="number" value={newEntryProfit} onChange={(e) => setNewEntryProfit(e.target.value)} required />
                    </label>
                    <label>Coin:
                    {renderCoinDropdown()}
                </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button onClick={handleClose} className="dicardChng">Verwerfen</button>
                </Col>
                <Col>
                  <button type="submit">{editingEntry ? "Speichern" : "Auswählen"}</button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <ToastContainer />

         <Modal  className="addNewUserModal addNewWalletModal resetPswModal" show={showResetPasswordModal} onHide={() => setShowResetPasswordModal(false)}>
        <Modal.Header style={divStyle}>
          <Modal.Title>Passwort zurücksetzen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {showResetPasswordModal && (
          <div>
            <label>Neues Passwort:
            <input
              type="password"
              placeholder=""
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            </label>
            <label>Neues Passwort bestätigen:
            <input
              type="password"
              placeholder=""
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            </label>
            <button className="resetCls" onClick={() => setShowResetPasswordModal(false)}>Verwerfen</button>
            <button className="resetSub" onClick={resetPassword}>Bestätigen</button>
            
          </div>
        )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      </Container>
    </>
  );
};

export default UserProfile;
