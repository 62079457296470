import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import DatePicker from 'react-datepicker';
import { getAuth, createUserWithEmailAndPassword  } from "firebase/auth";
import { auth } from '../../firebase-config';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import "react-datepicker/dist/react-datepicker.css";
import { doc, setDoc  } from "firebase/firestore";
import { db } from "../../firebase-config";
import { functions } from "../../firebase-config";
import { httpsCallable } from 'firebase/functions';


  const AddNewUser = ({ onSubmit }) => {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telefonnumer, setTelefonNumer] = useState("");
  const [goal, setGoal] = useState("");
  const [goalDate, setGoalDate] = useState("");
  const [startCapital, setStartCapital] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [investmentDate, setInvestmentDate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [role, setRole] = useState('Admin'); 

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (isSubmitting) return;
  
    setIsSubmitting(true);
  
    const auth = getAuth();
  
    try {
      const formData = {
        name,
        email,
        telefonnumer,
        goal,
        goalDate,
        startCapital,
        password,
        dob,
        investmentDate,
        role, // Include role in the form data
      };
  
      console.log("Form Values:", formData);
  
      const missingFields = Object.entries(formData).filter(([key, value]) => !value);
      if (missingFields.length > 0) {
        console.error("Some fields are missing:", missingFields.map(([key]) => key));
        setIsSubmitting(false);
        return;
      }
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      const newUser = {
        uid: user.uid,
        cname: String(name), 
        email, 
        dob: new Date(dob), 
        phone: telefonnumer, 
        investmentDetails: {
          investmentDate: new Date(investmentDate), 
          goalCapital: parseFloat(goal),
          startCapital: parseFloat(startCapital),
          goalDate: new Date(goalDate), 
          currentNetworth: parseFloat(startCapital),
        },
        role: formData.role, // Add role to Firestore document
      };
  
      console.log("New User Object Before Firestore:", newUser);
  
      // Add the new user to Firestore
      await setDoc(doc(db, "users", user.uid), newUser);
      console.log("User added with UID: ", user.uid);
  
      // Check if the role is 'Admin' and grant admin role
      if (formData.role === "Admin") {
        const grantAdminRole = httpsCallable(functions, 'grantAdminRole');
        try {
          await grantAdminRole({ userId: user.uid }); // Use correct parameter
          console.log("Admin role granted to user: ", user.uid);
        } catch (error) {
          console.error("Error granting admin role: ", error);
        }
      }
  
      onSubmit("Benutzer erfolgreich hinzugefügt!");
  
    } catch (error) {
      console.error("Error adding user: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <Col lg="12" md="12">
        <Card className="sidebar-bgColor shadow border-0">
         
          <CardBody className="px-lg-5 py-lg-5">
           
          <Form role="form" onSubmit={handleSubmit}>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="name">NAME/VORNAME</Label>
            <InputGroup className="input-group-alternative">
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="dob">GEBURSDATUM</Label>
            <InputGroup className="input-group-alternative">
              <DatePicker
                id="dob"
                selected={dob}
                onChange={(date) => setDob(date)}
                dateFormat="yyyy/MM/dd"
                className="form-control"
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="email">E-MAIL</Label>
            <InputGroup className="input-group-alternative">
              <Input
                type="email"
                autoComplete="new-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="phoneNumber">TELEFONNUMMER</Label>
            <InputGroup className="input-group-alternative">
              <Input
                type="text"
                value={telefonnumer}
                onChange={(e) => setTelefonNumer(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="password">PASSWORD</Label>
            <InputGroup className="input-group-alternative">
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="6">
        <FormGroup className="mb-3">
      <Label for="role">ROLLEN</Label>
      <InputGroup className="input-group-alternative">
        <Input
          type="select"
          id="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value="Admin">Admin</option>
          <option value="Kunde">Kunde</option>
        </Input>
      </InputGroup>
    </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="startCapital">STARTKAPITAL</Label>
            <InputGroup className="input-group-alternative">
              <Input
                type="number"
                value={startCapital}
                onChange={(e) => setStartCapital(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="investmentDate">DATUM DER INVESTITION</Label>
            <InputGroup className="input-group-alternative">
              <DatePicker
                id="investmentDate"
                selected={investmentDate}
                onChange={(date) => setInvestmentDate(date)}
                dateFormat="yyyy/MM/dd"
                className="form-control"
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="goal">GOAL</Label>
            <InputGroup className="input-group-alternative">
              <Input
                type="number"
                value={goal}
                onChange={(e) => setGoal(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label for="goalDate">DATUM DER GOAL</Label>
            <InputGroup className="input-group-alternative">
              <DatePicker
                id="goalDate"
                selected={goalDate}
                onChange={(date) => setGoalDate(date)}
                dateFormat="yyyy/MM/dd"
                className="form-control"
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button variant="secondary" type="button">
        Verwerfen
        </Button>
        <Button className="my-4" color="primary" type="submit">
        Benutzer erstellen
        </Button>
      </div>
    </Form>
          </CardBody>
        </Card>
       
      </Col>
    </>
  );
};

export default AddNewUser;
