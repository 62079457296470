// src/components/TradingFormModal.js

import React from "react";
import { Button, Modal } from 'react-bootstrap';
import TradingForm from './TradingForm';
import AddNewUserBg from '../../assets/img/theme/dashboardTopContentBg.png';

const TradingFormModal = ({ show, handleClose, onSuccess  }) => {

    const divStyle = {
        backgroundImage: `url(${AddNewUserBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
  return (
    <Modal show={show} className="addNewUserModal multipleTradingHistoryModal"  onHide={handleClose}>
      <Modal.Header style={divStyle}>
        <Modal.Title>Handelsverlauf hinzufügen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Include the TradingForm component */}
        <TradingForm onClose={handleClose} onSuccess={onSuccess}/>
        <div className="alertMsgForadm">!!! Bitte überprüfen Sie Ihre Angaben vor dem Absenden, da dieser Vorgang nicht rückgängig gemacht werden kann.</div>
      </Modal.Body>
    </Modal>
  );
};

export default TradingFormModal;
