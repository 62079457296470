
import starsImg from '../assets/img/icons/reviewsStar.png';
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Badge,
  Card,
  Col,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { getQuestionsBySurveyType, deleteQuestion } from "services/surveyService";
import Header from "components/Headers/Header.js";
import EditModal from 'components/Forms/EditSurveyModal';
import AddSurveyModal from 'components/Forms/AddSurveyModal';
import { Button, Modal } from 'react-bootstrap';

const RatingSurveys = () => {
  const [questions, setQuestions] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const surveyType = "RATING"; // Survey type for this component
  const surveyId = "IZcZUsQ6ARbqGWCFraAE"; // Replace with actual survey ID

  useEffect(() => {
    const fetchData = async () => {
      const data = await getQuestionsBySurveyType(surveyType);
      setQuestions(data);
    };

    fetchData();
  }, []);

  const handleEditClick = (question) => {
    setEditingQuestion(question);
    setModalShow(true);
  };

  const handleDeleteClick = async (questionId) => {
    await deleteQuestion(surveyId, questionId);
    setQuestions(questions.filter(question => question.id !== questionId));
  };

  const handleAddQuestionClick = () => {
    setAddModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
    setEditingQuestion(null);
  };

  const handleAddClose = () => {
    setAddModalShow(false);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
      <Row className="gx-3">
          <Col className="mb-5 mb-xl-0" xl="9">
          <Row>
           <Col className="headerRows mb-3 mt-3" lg="6" xl="4">
            <div>
            <span>Aktive Umfrage</span>
            <span className="mainN">4</span>
            </div>
           </Col>
           <Col className="headerRows mb-3 mt-3" lg="6" xl="4">
            <div>
           {/* <img src={Icon3h} alt="icon1" /> */}
           <span>Gesamt abgeschlossen</span>
            <span className="mainN">76</span>
            </div>
           </Col>
           </Row>
           <Row className="mt-3">
           </Row>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="3">
            <Row className="mb-3">
            <Col className="mb-3 hdButton" xl="12">
            <button className="addQuestionbtn" onClick={handleAddQuestionClick}>+ Frage hinzufügen</button>
            </Col>
            </Row>            
          </Col>
        </Row>
        <Row className="revCButtons mb-4">
        <Col className="" xl="12">
            <h3 className="text-white text-xl mb-4">FRAGEN</h3>
            </Col>
        </Row>
       
        <Row className="surveysContent">
  {questions.map((question) => (
    <Col xl="3" key={question.id}>
      <div className="surveyCBody">
        <div className="srvHeader">
          <p>{question.question}</p>
        </div>
        <div className="srvContent">
          <ul>
            {question.answers.map((answer, index) => (
              <li key={index}>{answer}</li>
            ))}
          </ul>
        </div>
        <div className="srvFooter">
          <button onClick={() => handleEditClick(question)}>Bearbeiten</button>
          <button onClick={() => handleDeleteClick(question.id)}>Löschen</button>
        </div>
      </div>
    </Col>
  ))}
</Row>
       
       {editingQuestion && (
        <EditModal 
          show={modalShow} 
          handleClose={handleClose} 
          question={editingQuestion} 
          setQuestions={setQuestions} 
          questions={questions} 
          setEditingQuestion={setEditingQuestion} 
        />
      )}
      <AddSurveyModal
        show={addModalShow}
        handleClose={handleAddClose}
        surveyId="IZcZUsQ6ARbqGWCFraAE"  // Replace with the actual survey ID
        setQuestions={setQuestions}
      />
      </Container>
    </>
  );
};

export default RatingSurveys;
