import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import Header from 'components/Headers/Header.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddCoinModal from 'components/Forms/AddNewCoin';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Row, Container } from "reactstrap";
import trashIcon from '../assets/img/icons/trash.png'; 
import 'react-toastify/dist/ReactToastify.css';

const Coins = () => {
  const [coins, setCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(null); // State to store the coin being edited
  const [showModal, setShowModal] = useState(false);

  const fetchCoins = async () => {
    try {
      const coinsCollection = collection(db, 'coins');
      const coinsSnapshot = await getDocs(coinsCollection);
      const coinsList = coinsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCoins(coinsList);
    } catch (error) {
      console.error('Error fetching coins:', error);
    }
  };

  useEffect(() => {
    fetchCoins();
  }, []);

  const handleFormSubmit = async (message) => {
    toast.success(message, {});
    fetchCoins(); 
  };

  const handleEditClick = (coin) => {
    setSelectedCoin(coin); 
    setShowModal(true); 
  };

  const handleDeleteClick = async (coinId) => {
    if (window.confirm('Are you sure you want to delete this coin?')) {
      try {
        const coinDoc = doc(db, 'coins', coinId);
        await deleteDoc(coinDoc);
        toast.success('Coin deleted successfully');
        fetchCoins(); // Refresh the coins list
      } catch (error) {
        console.error('Error deleting coin:', error);
        toast.error('Error deleting coin');
      }
    }
  };

  const symbolBodyTemplate = (rowData) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={rowData.logo}
        alt={rowData.symbol}
        style={{ width: '20px', height: '20px', marginRight: '10px' }}
      />
      <span>{rowData.symbol}</span>
    </div>
  );

  const actionBodyTemplate = (rowData) => (
    <React.Fragment>
       <button onClick={() => handleEditClick(rowData)} className="editProfitBtn">
       Bearbeiten
                    </button>
        <button onClick={() => handleDeleteClick(rowData.id)} className="deleteProfitBtn">
                      <img src={trashIcon} alt="Delete"/>
                    </button>
    </React.Fragment>
  );

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="9">
            <h3 className="text-white text-xl mb-4">Kryptowährungsliste</h3>
          </Col>
          <Col xl="3">
            <Button onClick={() => {
              setSelectedCoin(null); 
              setShowModal(true); 
            }} className='addNewCoinbtn'>Neue Krypto hinzufügen</Button>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <div className="coinsTable card-bgColor" style={{ padding: '30px' }}>
              <DataTable
                value={coins}
                paginator
                rows={20}
                tableStyle={{ minWidth: '50rem' }}
                sortField="order"
                sortOrder={1}
              >
                <Column
                  field="symbol"
                  header="Symbol"
                  body={symbolBodyTemplate}
                  style={{ width: '20%' }}
                ></Column>
                <Column field="color" header="Color" style={{ width: '20%' }}></Column>
                <Column
                  field="isFavorite"
                  header="Is Favourite"
                  body={(rowData) => (rowData.isFavorite ? 'Yes' : 'No')}
                  style={{ width: '20%' }}
                ></Column>
                <Column field="order" header="Order" style={{ width: '20%' }}></Column>
                <Column body={actionBodyTemplate} header="Actions" style={{ width: '20%' }}></Column>
              </DataTable>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
      <AddCoinModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        refreshCoins={fetchCoins}
        onSubmit={handleFormSubmit}
        coin={selectedCoin} // Pass the selected coin for editing
      />
    </>
  );
};

export default Coins;
