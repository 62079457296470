import { Timestamp } from "firebase/firestore"; // Import Firebase Timestamp if needed


export const walletDataArray = [
  {
    "coinData": {
      "order": 14,
      "symbol": "ALU",
      "color": "#272525",
      "isFavorite": false
    },
    "cryptoValue": 61114.26515900035
  },
  {
    "cryptoValue": 9781.733247615593,
    "coinData": {
      "color": "#ecece4",
      "symbol": "RSC",
      "isFavorite": true,
      "order": 6
    }
  },
  {
    "coinData": {
      "color": "#c34b4b",
      "order": 7,
      "isFavorite": false,
      "symbol": "GFAL"
    },
    "cryptoValue": 116259.50031461188
  },
  {
    "cryptoValue": 10226.406800543413,
    "coinData": {
      "symbol": "ORNJ",
      "order": 20,
      "isFavorite": false,
      "color": "#ab8349"
    }
  },
  {
    "cryptoValue": 9779.588962529539,
    "coinData": {
      "symbol": "USDT",
      "isFavorite": false,
      "order": 4,
      "color": "#cce8b5"
    }
  },
  {
    "cryptoValue": 172.09328461296903,
    "coinData": {
      "color": "#beee11",
      "isFavorite": false,
      "order": 24,
      "symbol": "PRIME"
    }
  },
  {
    "cryptoValue": 228.40613527054248,
    "coinData": {
      "color": "#000000",
      "isFavorite": false,
      "symbol": "ORAI",
      "order": 41
    }
  },
  {
    "coinData": {
      "order": 15,
      "symbol": "GTAI",
      "isFavorite": false,
      "color": "#5394ea"
    },
    "cryptoValue": 3047.243366891629
  },
  {
    "coinData": {
      "color": "#335dd2",
      "symbol": "LINK",
      "isFavorite": false,
      "order": 32
    },
    "cryptoValue": 116.40014062615172
  },
  {
    "cryptoValue": 18306.9383634718,
    "coinData": {
      "order": 9,
      "color": "#918888",
      "symbol": "GSWIFT",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": 3685.6206520052456,
    "coinData": {
      "isFavorite": false,
      "order": 25,
      "symbol": "MAGIC",
      "color": "#c93636"
    }
  },
  {
    "coinData": {
      "isFavorite": false,
      "color": "#c2c2c2",
      "order": 30,
      "symbol": "AIOZ"
    },
    "cryptoValue": 3180.28716799389
  },
  {
    "coinData": {
      "symbol": "ONDO",
      "isFavorite": false,
      "color": "#202122",
      "order": 22
    },
    "cryptoValue": 1972.828210430943
  },
  {
    "cryptoValue": 5217.829097506795,
    "coinData": {
      "isFavorite": false,
      "symbol": "ZETA",
      "order": 19,
      "color": "#005640"
    }
  },
  {
    "cryptoValue": 16.290912233183274,
    "coinData": {
      "isFavorite": false,
      "color": "#9391f7",
      "symbol": "AAVE",
      "order": 35
    }
  },
  {
    "cryptoValue": 0.4214537134708373,
    "coinData": {
      "symbol": "BTC",
      "order": 1,
      "color": "#f00000",
      "isFavorite": true
    }
  },
  {
    "cryptoValue": 52.60569610682325,
    "coinData": {
      "symbol": "QNT",
      "order": 38,
      "color": "#fafafa",
      "isFavorite": false
    }
  },
  {
    "coinData": {
      "symbol": "ROOT",
      "isFavorite": false,
      "color": "#0f0f0f",
      "order": 40
    },
    "cryptoValue": 52653.72989999541
  },
  {
    "cryptoValue": 1148.3090974525524,
    "coinData": {
      "order": 23,
      "symbol": "ARKM",
      "isFavorite": false,
      "color": "#0c0d0d"
    }
  },
  {
    "coinData": {
      "order": 5,
      "isFavorite": false,
      "color": "#e3e3d9",
      "symbol": "FET"
    },
    "cryptoValue": 4685.424514146367
  },
  {
    "coinData": {
      "color": "#272525",
      "symbol": "PASG",
      "isFavorite": false,
      "order": 39
    },
    "cryptoValue": 147558.9926742056
  },
  {
    "coinData": {
      "color": "#103ca2",
      "symbol": "SNT",
      "isFavorite": true,
      "order": 29
    },
    "cryptoValue": 57125.01030016833
  },
  {
    "cryptoValue": 6653.929059674469,
    "coinData": {
      "symbol": "MATIC",
      "order": 37,
      "color": "#8b36d7",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": 975.795909162308,
    "coinData": {
      "order": 18,
      "color": "#0a0a0b",
      "isFavorite": false,
      "symbol": "IMX"
    }
  },
  {
    "coinData": {
      "color": "#dddfff",
      "isFavorite": true,
      "order": "2",
      "symbol": "ETH"
    },
    "cryptoValue": 5.181516391068115
  },
  {
    "cryptoValue": 1011.9185747304294,
    "coinData": {
      "order": 42,
      "symbol": "MAVIA",
      "color": "#215dd3",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": 1289.876162830279,
    "coinData": {
      "order": 21,
      "isFavorite": false,
      "color": "#fda018",
      "symbol": "NMT"
    }
  },
  {
    "cryptoValue": 136089.57157351062,
    "coinData": {
      "order": 31,
      "isFavorite": false,
      "symbol": "CUDOS",
      "color": "#257ef4"
    }
  },
  {
    "coinData": {
      "isFavorite": false,
      "color": "#8cabf2",
      "order": 27,
      "symbol": "SAND"
    },
    "cryptoValue": 4898.026561171785
  },
  {
    "coinData": {
      "isFavorite": false,
      "symbol": "DOT",
      "color": "#e6007a",
      "order": 36
    },
    "cryptoValue": 594.7022070833957
  },
  {
    "cryptoValue": 707.195611717963,
    "coinData": {
      "symbol": "TON",
      "color": "#4b74d2",
      "isFavorite": false,
      "order": 28
    }
  },
  {
    "coinData": {
      "order": 26,
      "isFavorite": false,
      "color": "#d0b9b9",
      "symbol": "BEAM"
    },
    "cryptoValue": 92478.0139395014
  },
  {
    "cryptoValue": 9620.80482499375,
    "coinData": {
      "symbol": "PORTAL",
      "isFavorite": false,
      "order": 12,
      "color": "#d6d6d6"
    }
  },
  {
    "cryptoValue": 19702.323463240245,
    "coinData": {
      "order": 34,
      "symbol": "ALGO",
      "color": "#ebebeb",
      "isFavorite": false
    }
  },
  {
    "cryptoValue": 11078.113604927039,
    "coinData": {
      "color": "#4a4a4a",
      "isFavorite": false,
      "symbol": "CGPT",
      "order": 8
    }
  },
  {
    "coinData": {
      "color": "#242428",
      "order": 33,
      "symbol": "ATOM",
      "isFavorite": false
    },
    "cryptoValue": 297.2347976326763
  },
  {
    "cryptoValue": 133.69702757669603,
    "coinData": {
      "color": "#6e65e6",
      "order": 10,
      "isFavorite": false,
      "symbol": "INJ"
    }
  },
  {
    "cryptoValue": 72.32499831990256,
    "coinData": {
      "isFavorite": true,
      "color": "#fff333",
      "order": 3,
      "symbol": "SOL"
    }
  },
  {
    "coinData": {
      "symbol": "RON",
      "order": 11,
      "isFavorite": false,
      "color": "#453bce"
    },
    "cryptoValue": 1627.0197758580841
  }
]