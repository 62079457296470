// src/EditModal.js
import React from "react";
import { Modal, Button } from "react-bootstrap";
import EditForm from "./EditSurveyForm";
import AddNewUserBg from '../../assets/img/theme/dashboardTopContentBg.png';

const EditModal = ({ show, handleClose, question, setQuestions, questions, setEditingQuestion }) => {
  const divStyle = {
    backgroundImage: `url(${AddNewUserBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '40px',
  };
  return (
    <Modal className="addNewUserModal editQuestionModal  addNewWalletModal" show={show} onHide={handleClose}>
      <Modal.Header style={divStyle}>
        <Modal.Title>Frage bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditForm 
          question={question} 
          setQuestions={setQuestions} 
          questions={questions} 
          setEditingQuestion={setEditingQuestion} 
          handleClose={handleClose} 
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
