import React, { useState } from "react";
import { getFirestore, collection, getDocs, doc, updateDoc } from "firebase/firestore"; // Firestore setup

const UpdateWalletForm = () => {
  const [userId, setUserId] = useState(""); // State to hold the user ID
  const [message, setMessage] = useState(""); // State to hold the status message
  const db = getFirestore(); // Initialize Firestore

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission from refreshing the page

    if (!userId) {
      setMessage("Please enter a valid client ID.");
      return;
    }

    try {
      // Reference the 'wallet' subcollection under the specific 'user' document
      const walletRef = collection(doc(db, "users", userId), "wallet");

      // Fetch all the documents from the 'wallet' subcollection
      const querySnapshot = await getDocs(walletRef);

      // Loop through each document in the subcollection
      const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
        const docData = docSnapshot.data();
        let { cryptoValue } = docData;

        // Check if cryptoValue is a string and contains commas
        if (typeof cryptoValue === "string" && cryptoValue.includes(",")) {
          // Replace commas with dots and convert to a float number
          const updatedCryptoValue = parseFloat(cryptoValue.replace(/,/g, "."));

          // Update the document in Firestore with the new numeric cryptoValue
          await updateDoc(docSnapshot.ref, {
            cryptoValue: updatedCryptoValue
          });

          console.log(`Updated document ${docSnapshot.id}: cryptoValue ->`, updatedCryptoValue);
        }
      });

      // Wait for all update operations to complete
      await Promise.all(updatePromises);

      setMessage("All documents updated successfully!");
    } catch (error) {
      console.error("Error updating wallet data:", error);
      setMessage("Error updating wallet data.");
    }
  };

  return (
    <div>
      <h2>Update Wallet Data</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="clientId">Client ID:</label>
          <input
            type="text"
            id="clientId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="Enter Client ID"
            required
          />
        </div>
        <button type="submit">Update Wallet Data</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default UpdateWalletForm;
