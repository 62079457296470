import { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { collection, getDocs, query, where, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import trashIcon from '../assets/img/icons/trash.png';   

const UserReports = () => {
    const [reports, setReports] = useState([]);
    const [selectedTab, setSelectedTab] = useState("BUG"); // Default to "BUG"

    useEffect(() => {
      const fetchReports = async () => {
        try {
          const reportsCollection = collection(db, "userReports");
          const q = query(reportsCollection, where("reportType", "==", selectedTab), where("isActive", "==", true));
          const reportsSnapshot = await getDocs(q);
          
          const results = [];
          
          reportsSnapshot.forEach((resultDoc) => {
            const data = resultDoc.data();
            const createdAt = data.createdAt.toDate(); // Convert Firestore Timestamp to Date

            const formattedDate = `${createdAt.getDate().toString().padStart(2, '0')}.${(createdAt.getMonth() + 1).toString().padStart(2, '0')}.${createdAt.getFullYear()}`;

            results.push({
              id: resultDoc.id,
              name: data.userName,
              title: data.title,
              createdAt: formattedDate, // Use formatted date
              description: data.description 
            });
          });

          setReports(results);
        } catch (error) {
          console.error("Error fetching reports: ", error);
        }
      };

      fetchReports();
    }, [selectedTab]);

    const deleteReport = async (id) => {
        try {
          await deleteDoc(doc(db, "userReports", id));
          setReports(reports.filter((report) => report.id !== id));
        } catch (error) {
          console.error("Error deleting report: ", error);
        }
      };
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="gx-3">
            <Col className="mb-5 mb-xl-0" xl="9">
              <h3 className="text-white text-xl mb-4">Benutzerberichte</h3>
            </Col>
          </Row>
          <Row className="revCButtons mb-4">
            <Col xl="12">
              <Button
                color={selectedTab === "BUG" ? "primary" : "secondary"}
                onClick={() => setSelectedTab("BUG")}
              >
                Fehlerberichte
              </Button>
              <Button
                color={selectedTab === "FEATURE_REQUEST" ? "primary" : "secondary"}
                onClick={() => setSelectedTab("FEATURE_REQUEST")}
              >
                FEATURE-ANFRAGE
              </Button>
            </Col>
          </Row>
          <Row className="gx-3 reviewsContent mb-3">
            {reports.length > 0 ? (
              reports.map((report) => (
                <Col  className="mb-3" xl="3">
                  <div key={report.id} className="reportContent">
                    <div className="rUsrImage"></div>
                    <p className="rUsrName">{report.name}</p>
                    <p className="rDate">{report.createdAt}</p>
                    <p className="ratingNumber">{report.title}</p>
                    <p className="rComment">{report.description}</p>
                    <Button  onClick={() => deleteReport(report.id)}><img src={trashIcon} alt="Delete"/></Button>
                  </div>
                </Col>
              ))
            ) : (
              <p>No reports available.</p>
            )}
          </Row>
        </Container>
      </>
    );
};

export default UserReports;
