import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Table,
  Container,
} from "reactstrap";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { auth, db, storage } from "../firebase-config";
import Header from "components/Headers/Header.js";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import BTCWidget from '../components/Widgets/BTCWidget';
import ETHWidget from '../components/Widgets/ETHWidget';
import MainWidget from '../components/Widgets/MainWidget';
import Icon1h from '../assets/img/icons/IconH1.png';
import Icon2h from '../assets/img/icons/IconH2.png';
import Icon3h from '../assets/img/icons/IconH3.png';
import AddNewUserBg from '../assets/img/theme/dashboardTopContentBg.png';
import { getUserCount, getTotalStartCapital, getTotalCoins } from "utils/statsUtils";


const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [userCount, setUserCount] = useState(0);
  const [totalStartCapital, setTotalStartCapital] = useState(0)
  const [coinsCount, setCoinsCount] = useState(0);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const divStyle = {
    backgroundImage: `url(${AddNewUserBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const count = await getUserCount();
        setUserCount(count);

        const totalCapital = await getTotalStartCapital();
        setTotalStartCapital(totalCapital);

        const coinsCount = await getTotalCoins();
        setCoinsCount(coinsCount);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

 const navigate = useNavigate()
 const [data, setData] = useState({});
 const [currency, setCurrency] = useState("");
  const [name, setName] = useState("");


 let i = 0;
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      <h3 className="text-white text-xl mb-4">Übersicht</h3>
        <Row className="gx-3">
          <Col className="mb-5 mb-xl-0" xl="8">
          <Row>
           <Col className="headerRows mb-3 mt-3" lg="6" xl="4">
            <div style={divStyle}>
            <img src={Icon1h} alt="icon1" />
            <span>Gesamtkunden</span>
            <span className="mainN">{userCount.toLocaleString()}</span>
            </div>
           </Col>
           <Col className="headerRows mb-3 mt-3" lg="6" xl="4">
            <div style={divStyle}>
           <img src={Icon2h} alt="icon1" />
           <span>Gesamteinlagen</span>
            <span className="mainN">{totalStartCapital.toLocaleString()} CHF</span>
            </div>
           </Col>
           <Col className="headerRows mb-3 mt-3" lg="6" xl="4">
            <div style={divStyle}>
           <img src={Icon3h} alt="icon1" />
           <span>Kryptowährungs</span>
            <span className="mainN">{coinsCount}</span>
            </div>
           </Col>
           </Row>
           <Row className="mt-3">
            <MainWidget/>
           </Row>
          </Col>
          <Col className="mb-5 mb-xl-0 coinStat" xl="4">
            <span>In den letzten 24 Stunden</span>
            <h3>Markt ist um 6,73% gestiegen</h3>
            <Row className="mb-3">
            <Col className="mb-3" xl="12">
            <BTCWidget></BTCWidget>
            </Col>
            <Col className="mb-3" xl="12">
            <ETHWidget></ETHWidget>
            </Col>
            </Row>            
          </Col>
        </Row>
        <Row>
          <Col>
          {/* <Form role="form" onSubmit={handleAdd}>
          <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Currency"
                    type="text"
                    autoComplete="new-email"
                    onChange={(e) => setCurrency(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Name"
                    type="text"
                    autoComplete="new-password"
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <button  type="submit">
                Send
              </button>
              </Form> */}

           </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
