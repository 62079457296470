// src/components/ChatPage.js
import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { collection, getDocs, orderBy, onSnapshot, query } from "firebase/firestore";
import { format } from "date-fns";

const MessageList = ({ chatId, adminId }) => {
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState({});

  useEffect(() => {
      const fetchUsers = async () => {
          try {
              const usersCollection = collection(db, "users");
              const usersSnapshot = await getDocs(usersCollection);
              const usersList = usersSnapshot.docs.reduce((acc, doc) => {
                  acc[doc.id] = doc.data().name;
                  return acc;
              }, {});
              setUsers(usersList);
              console.log("Users fetched:", usersList);
          } catch (error) {
              console.error("Error fetching users:", error);
          }
      };

      fetchUsers();
  }, []);

  useEffect(() => {
      if (chatId) {
          const messagesCollection = collection(db, `chat/${chatId}/messages`);
          console.log("Setting up onSnapshot for chatId:", chatId);
          const q = query(messagesCollection, orderBy("time"));
          const unsubscribe = onSnapshot(q, (snapshot) => {
              const messagesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
              console.log("Messages updated:", messagesList);
              setMessages(messagesList);
          }, (error) => {
              console.error("Error fetching messages:", error);
          });

          // Clean up the subscription when the component unmounts or chatId changes
          return () => unsubscribe();
      } else {
          console.log("No chatId provided.");
      }
  }, [chatId]);

  const formatTimestamp = (timestamp) => {
      if (!timestamp) return "";
      return format(timestamp.toDate(), "dd/MM/yyyy HH:mm:ss");
  };

  return (
      <div className="message-list">
          <ul>
              {messages.length === 0 && <li>No messages available</li>}
              {messages.map(message => (
                  <li key={message.id || message.time.toString()} className={message.senderId === "adminId" ? 'admin-message' : ''}>
                    <div>
                    {message.senderId !== adminId && (
                      <strong>{users[message.senderId] || message.senderId}:</strong> 
                    )}
                      <span className="message-time">{formatTimestamp(message.time)}</span></div>
                      <div className="chatMessageContent"><p>{message.message}</p></div>
                      
                    
                  </li>
              ))}
          </ul>
      </div>
  );
};

export default MessageList;
